import React, { useState, useEffect } from "react";
import Select from "react-select";


const CountryOptions = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  
  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
   
  };
  const postData = {
    // Include any data you need to send in the POST request
  };
  useEffect(() => {
    
    const fetchData = async () => {
      const postData = {
        // Include any data you need to send in the POST request
      };

      try {
        const response = await fetch('https://app.jimcaaledeals.com/coreapp/properties/api/getCities', {
          method: 'POST',
          headers: {
            'token': 'ba413be8-3d95-4bb9-b495-9ff70c809c27',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(postData)  // Make sure to stringify the body payload
        });

        const res = await response.json(); // Assuming the server responds with JSON

        if (response.ok) {
          if (res.status) {
            setData(res.message); // Adjust according to your API response
            setLoading(false);
            const filteredCities = res.message.filter(obj => obj.value  === props.value)[0]
            if (filteredCities) {
              setSelectedOption(filteredCities);
            }else{
              setSelectedOption({ value: '', label: 'Select Location' });
            }
          } else {
            throw new Error(res.message || 'Failed to fetch data');
          }
        } else {
          throw new Error(res.message || 'Failed to fetch data');
        }
      } catch (error) {
        setError(error.message || 'An error occurred while fetching property types');
      }
    };

    fetchData();
    
  }, []); // Empty dependency array ensures this effect runs only once after the initial render



  if (error) {
    console.log(error)
    
  }

  if (loading) {
    return <div></div>;
  }
  const colourStyles = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: "none",
      padding: "12px 0 12px 35px",
      margin: "-16px 0 0 -45px",
      borderRadius: "0",
      outline: "none"
    })
  };

  const options = [{ value: '', label: 'Select All' }, ...data];

  return (
    <React.Fragment>
      <Select
        options={options}
        className="choices selectForm__inner "
        styles={colourStyles}
        value={selectedOption}
        onChange={(choice) => {
          props.onSelectedValue(choice.value)
          handleChange(choice)
          
        }}
      />
    </React.Fragment>
  );
};

export default CountryOptions;
