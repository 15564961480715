import React, { useState, useEffect } from "react";

import { Col, Container, Row } from "reactstrap";
import JobDetailsDescription from "./JobDetailsDescription";
import JobVacancyPost from "./JobVacancyPost";
import RightSideContent from "./RightSideContent";
import Section from "./Section";
import { useLocation } from 'react-router-dom';
import BlogSwiper from "../../Blog/BlogDetails/BlogSwiper";

const JobDetails = () => {
  document.title = "Apartment Details";

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  let location = useLocation();
  let queryParams = new URLSearchParams(location.search);

  const biud = queryParams.get('b');
 
  useEffect(() => {
    
    const fetchData = async () => {
      const postData = {
        "pid":biud
      };

      try {
        const response = await fetch('https://app.jimcaaledeals.com/coreapp/properties/api/getSpecificProperty', {
          method: 'POST',
          headers: {
            'token': 'ba413be8-3d95-4bb9-b495-9ff70c809c27',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(postData)  // Make sure to stringify the body payload
        });

        const res = await response.json(); // Assuming the server responds with JSON

        if (response.ok) {
          if (res.status) {
            setData(res.message); // Adjust according to your API response
            setLoading(false);
          } else {
            throw new Error(res.message || 'Failed to fetch data');
          }
        } else {
          throw new Error(res.message || 'Failed to fetch data');
        }
      } catch (error) {
        setError(error.message || 'An error occurred while fetching property types');
      }
    };

    fetchData();
    
  }, []); // Empty dependency array ensures this effect runs only once after the initial render



  if (error) {
    console.log(error)
    
  }

  if (loading) {
    return <div></div>;
  }

  return (
    <React.Fragment>
      <Section data={data}/>
      <section className="section">
        <Container>
          <Row>
            <Col lg={8}>
            {/* <BlogSwipe r /> */}

              <JobDetailsDescription data={data} />
            
            </Col>
            <Col lg={4} className="mt-4 mt-lg-0">
              <RightSideContent data={data}/>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default JobDetails;
