import React,{ useState, useEffect } from "react";
import Select from "react-select";
const JobType = (props) => {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };
  const postData = {
    // Include any data you need to send in the POST request
  };
  useEffect(() => {
    const fetchData = async () => {
      const postData = {
        // Include any data you need to send in the POST request
      };

      try {
        const response = await fetch('https://app.jimcaaledeals.com/coreapp/properties/api/getBusinessCategories', {
          method: 'POST',
          headers: {
            'token': 'ba413be8-3d95-4bb9-b495-9ff70c809c27',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(postData)  // Make sure to stringify the body payload
        });

        const data = await response.json(); // Assuming the server responds with JSON

        if (response.ok) {
          if (data.status) {
            setData(data.message); // Adjust according to your API response
            setLoading(false);
            const filteredCateg = data.message.filter(obj => obj.value  === props.value)[0]
            if (filteredCateg) {
              setSelectedOption(filteredCateg);
            }else{
              setSelectedOption({ value: '', label: 'Select Category' });
            }
          } else {
            throw new Error(data.message || 'Failed to fetch data');
          }
        } else {
          throw new Error(data.message || 'Failed to fetch data');
        }
      } catch (error) {
        setError(error.message || 'An error occurred while fetching property types');
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures this effect runs only once after the initial render

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (loading) {
    return <div>Loading...</div>;
  }
  
 
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      border: 0,
      boxShadow: "none",
      padding: "12px 0 12px 40px",
      margin: "-16px -6px 0 -52px",
      borderRadius: "0"
    })
  };

  const options = [{ value: '', label: 'Select All' }, ...data];

  return (
    <React.Fragment>
      <Select
        options={options}
        styles={colourStyles}
        className="selectForm__inner"
        data-trigger
        defaultValue={{ label: "All", value: 0 }}
        value={selectedOption}
        name="choices-single-categories"
        id="choices-single-categories"
        aria-label="Default select example"
        onChange={(choice) => {
          props.onSelectedValue(choice.value)
          handleChange(choice)
        }}
      />
    </React.Fragment>
  );
};

export default JobType;
