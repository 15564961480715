import React from "react";
import { Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Input, Row } from "reactstrap";
import CountryOptions from "../../Home/SubSection/CountryOptions";
import JobType from "../../Home/SubSection/JobType";

const JobSearchOptions = ({urlData,businessName , selectedCategory , selectedCity,  onFilter}) => {

 

  return (
    <React.Fragment>
      <div className="job-list-header">
        {/* <Form action="#">
          <Row className="g-2" style={{display:"flex" , alignItems:"center" , justifyContent:"center"}}>
            <Col lg={12} md={12}>
              <div className="filler-job-form" style={{padding:"10px" , border:"1px solid red" , borderRadius:"10px",

              }}>
                <i className="uil uil-briefcase-alt"></i>
                <Input
                  type="search"
                  className="form-control filter-input-box"
                  id="exampleFormControlInput1"
                  placeholder="Business, company... "
                
                  style={{ marginTop: "-10px" }}
                  onChange={(e)=>{
                  
                    businessName(e.target.value)
                  
                  }}
                />
              </div>
            </Col>
           
           
          
          </Row>
        </Form> */}
        <form action="#">
        <Row className="g-2">
          <Col lg={3} md={6}>
            <div className="filler-job-form">
              <i className="uil uil-briefcase-alt"></i>
              <Input
                type="search"
                className="form-control filter-input-box"
                id="exampleFormControlInput1"
                placeholder="Apartments, Villas"
                style={{ marginTop: "-10px" }}
                onChange={(e)=>{
                  
                  businessName(e.target.value)
                
                }}
              />
            </div>
          </Col>
          <Col lg={3} md={6}>
            <div className="filler-job-form">
              <i className="uil uil-location-point"></i>
              <CountryOptions value={urlData.city} onSelectedValue={selectedCity} onChange={onFilter}/>
            </div>
          </Col>
          <Col lg={3} md={6}>
            <div className="filler-job-form">
              <i className="uil uil-clipboard-notes"></i>
              <JobType value={urlData.categ} onSelectedValue={selectedCategory}/>
            </div>
          </Col>
          <Col lg={3} md={6}>
            <Link to="#" className="btn btn-primary w-100">
              <i className="uil uil-filter"></i> Fliter
            </Link>
          </Col>
        </Row>
      </form>
      </div>
    </React.Fragment>
  );
};

export default JobSearchOptions;
