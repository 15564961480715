import React, { useEffect, useState } from "react";
import { Col, Row, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

import blogImage4 from "../../../assets/images/blog/img-04.jpg";
import blogImage5 from "../../../assets/images/blog/img-05.jpg";
import blogImage6 from "../../../assets/images/blog/img-06.jpg";
import blogImage7 from "../../../assets/images/blog/img-07.jpg";
import blogImage8 from "../../../assets/images/blog/img-08.jpg";
import blogImage9 from "../../../assets/images/blog/img-09.jpg";

const BlogText = ({ data }) => {
  const blogText = [
    {
      id: 1,
      blogImage: blogImage4,
      blogAuther: "Alice Mellor",
      blogDate: "Aug 08, 2021",
      blogCount: 432,
      blogTitle: "Smartest Applications for Business",
      blogContent:
        "Intrinsically incubate intuitive opportunities and real-time potentialities Appropriately communicate one-to-one technology.",
    },
    {
      id: 2,
      blogImage: blogImage5,
      blogAuther: "Kiera Finch",
      blogDate: "July 23, 2021",
      blogCount: 247,
      blogTitle: "How To Increase Trade Show Traffic",
      blogContent:
        "Intrinsically incubate intuitive opportunities and real-time potentialities Appropriately communicate one-to-one technology.",
    },
    {
      id: 3,
      blogImage: blogImage6,
      blogAuther: "Toby Lees",
      blogDate: "July 11, 2021",
      blogCount: 188,
      blogTitle: "How apps is changing the IT world",
      blogContent:
        "Intrinsically incubate intuitive opportunities and real-time potentialities Appropriately communicate one-to-one technology.",
    },
    {
      id: 4,
      blogImage: blogImage7,
      blogAuther: "Dominic Potter",
      blogDate: "June 19, 2021",
      blogCount: 475,
      blogTitle: "Design your apps in your own way.",
      blogContent:
        "Intrinsically incubate intuitive opportunities and real-time potentialities Appropriately communicate one-to-one technology.",
    },
    {
      id: 5,
      blogImage: blogImage8,
      blogAuther: "Leon Davey",
      blogDate: "June 04, 2021",
      blogCount: 310,
      blogTitle: "How to get creative in your work",
      blogContent:
        "Intrinsically incubate intuitive opportunities and real-time potentialities Appropriately communicate one-to-one technology.",
    },
    {
      id: 6,
      blogImage: blogImage9,
      blogAuther: "Harvey Bird",
      blogDate: "Feb 28, 2021",
      blogCount: 158,
      blogTitle: "What planning process needs ?",
      blogContent:
        "Intrinsically incubate intuitive opportunities and real-time potentialities Appropriately communicate one-to-one technology.",
    },
  ];
  const blogVideo = [
    {
      id: 1,
      blogLink: "https://www.youtube.com/embed/1y_kfWUCFDQ",
      blogAuther: "Harvey Bird",
      blogDate: "Feb 21, 2021",
      blogCount: 110,
      blogTitle: "How to become a best sale marketer in a year!",
      blogContent:
        "Intrinsically incubate intuitive opportunities and real-time potentialities Appropriately communicate one-to-one technology.",
    },
    {
      id: 2,
      blogLink: "https://www.youtube.com/embed/1y_kfWUCFDQ",
      blogAuther: "Harvey Bird",
      blogDate: "Feb 09, 2021",
      blogCount: 244,
      blogTitle: "A day in the of a professional fashion designer",
      blogContent:
        "Intrinsically incubate intuitive opportunities and real-time potentialities Appropriately communicate one-to-one technology.",
    },
  ];

  console.log("Agent ID is: ", data.AGENT)
  const [optionsData, setData] = useState([]);

  const fetchData = async () => {
    const postData = {
      pagent: data.AGENT,
      pid: data.ID,
    };

    try {
      const response = await fetch(
        "https://app.jimcaaledeals.com/coreapp/properties/api/getSimilarProperty",
        {
          method: "POST",
          headers: {
            token: "ba413be8-3d95-4bb9-b495-9ff70c809c27",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData), // Make sure to stringify the body payload
        }
      );

      const res = await response.json(); // Assuming the server responds with JSON

      if (response.ok) {
        if (res.status) {
          setData(res.message); // Adjust according to your API response

          console.log("Options Data:", optionsData);
        } else {
          setData({}); // Adjust according to your API response
        }
      } else {
        setData({}); // Adjust according to your API response
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, []); // Empty dependency array ensures this effect runs only once after the initial render

  // const { location, match } = this.props;

  // const refresh =(id) =>{

  // const bobUrl = location.pathname.replace(match.params.b, id);

  // return bobUrl;
  // }
  return (
    <React.Fragment>
      <Row>
      {optionsData.length > 0 &&(
            optionsData.map((dataItems, key) => (
              <Col lg={6} className="mb-4" key={key}>
                <Card className="blog-grid-box p-2">
                  <img
                    src={dataItems.PHOTO}
                    alt=""
                    className="img-fluid"
                  />
                  <CardBody>
                    <Link to={`/bussinessDetails?b=${dataItems.ID}`} target='_blank' className="primary-link">
                      <h6 className="fs-17">{dataItems.NAME}</h6>
                    </Link>
                    <p className="text-muted">{dataItems.DESCRIPTION}</p>
                    <div>
                      <Link to={`/bussinessDetails?b=${dataItems.ID}`} target='_blank' className="form-text text-primary">
                        Read More <i className="uil uil-angle-right-b"></i>
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))
          )}
          
          
          {!optionsData.length &&(
            <Col lg={6} className="mb-4">
                {/* <Card className="p-2">
                  
                  <CardBody> */}
                    
                    <p className="text-muted p-2">No Data Found about this Contractor</p>
                    
                  {/* </CardBody>
                </Card> */}
              </Col>
          
        )} 

        {/* {blogVideo.map((blogVideoDetails, key) => (
          <Col lg={6} mb={4} key={key}>
            <Card className="blog-grid-box p-2">
              <div className="ratio ratio-16x9">
                {" "}
                <iframe
                  src={blogVideoDetails.blogLink}
                  title="YouTube video"
                  allowFullScreen
                ></iframe>{" "}
              </div>
              <CardBody>
                <ul className="list-inline d-flex justify-content-between mb-3">
                  <li className="list-inline-item">
                    <p className="text-muted mb-0">
                      <Link to="/blogauther" className="text-muted fw-medium">
                        {blogVideoDetails.blogAuther}
                      </Link>{" "}
                      - {blogVideoDetails.blogDate}
                    </p>
                  </li>
                  <li className="list-inline-item">
                    <p className="text-muted mb-0">
                      <i className="mdi mdi-eye"></i>{" "}
                      {blogVideoDetails.blogCount}
                    </p>
                  </li>
                </ul>
                <Link to="/blogdetails" className="primary-link">
                  <h6 className="fs-17">{blogVideoDetails.blogTitle}</h6>
                </Link>
                <p className="text-muted">{blogVideoDetails.blogContent}</p>
                <div>
                  <Link to="/blogdetails" className="form-text text-primary">
                    Read More <i className="uil uil-angle-right-b"></i>
                  </Link>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))} */}
      </Row>
    </React.Fragment>
  );
};

export default BlogText;
