import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Input, Label, Row, Modal, ModalBody } from "reactstrap";

//Images Import
import FeaturedLists from "../../../Layout/CommonLayout/featuredLists";

const JobVacancyList = ({data}) => {
  //Apply Now Model
  

  if (!data) {
    return <div></div>;
  }

  return (
    <FeaturedLists data={data} ></FeaturedLists>
   );
};

export default JobVacancyList;
