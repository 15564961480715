import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Section from "../../Jobs/JobList/Section";
import JobSearchOptions from "./JobSearchOptions";
import JobFilters from "../../CandidateAndCompany/CandidateList/JobFilters";

import JobVacancyList from "./JobVacancyList";
import Popular from "./Popular";
import Sidebar from "./Sidebar";
import { useLocation } from "react-router-dom";

const JobList = () => {
  let location = useLocation();
  let queryParams = new URLSearchParams(location.search);

  const bname = queryParams.get("bname");
  const categ = queryParams.get("categ");
  const city = queryParams.get("city");

  const [selectedCategoryVal, setselectedCategoryVal] = useState(categ);
  const [selectedCityVal, setSelectedCityVal] = useState(city);
  const [businessNameVal, setBusinessNameVal] = useState(bname);

  function selectedCity(selectedCategoryV) {
    setSelectedCityVal(selectedCategoryV);
  }

  function selectedCategory(selectedCategoryV) {
    setselectedCategoryVal(selectedCategoryV);
  }

  function businessName(businessNameV) {
    setBusinessNameVal(businessNameV);
  }

  const [data, setData] = useState([]);

  const fetchData = async () => {
    const postData = {
      categ: categ,
      city:city,
      selectedCategoryVal:selectedCategoryVal,
      selectedCityVal:selectedCityVal,
      businessNameVal:businessNameVal
    };

    try {
      const response = await fetch(
        "https://app.jimcaaledeals.com/coreapp/properties/api/getPropertiesByCategory",
        {
          method: "POST",
          headers: {
            'token': 'ba413be8-3d95-4bb9-b495-9ff70c809c27',
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData), // Make sure to stringify the body payload
        }
      );

      const res = await response.json(); // Assuming the server responds with JSON

      if (response.ok) {
        if (res.status) {
          setData(res.message); // Adjust according to your API response
        } else {
          setData({}); // Adjust according to your API response
        }
      } else {
        setData({}); // Adjust according to your API response
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, [selectedCategoryVal, selectedCityVal, businessNameVal]); // Empty dependency array ensures this effect runs only once after the initial render

  document.title = "Jimcaale Deals";
  return (
    <React.Fragment>
      {/* <Section data={data}/> */}
      <section className="section mt-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="me-lg-5">
                <JobSearchOptions
                  onFilter={fetchData}
                  businessName={businessName}
                  urlData={{
                    bname: businessNameVal,
                    city: selectedCityVal,
                    categ: selectedCategoryVal,
                  }}
                />
              </div>
            </Col>
          </Row>
          <div className="col-md-12 col-lg-12">
            <div className="d-flex gap-5">
              {/* <Container> */}
                <JobVacancyList data={data} />
              {/* </Container> */}
            </div>
          </div>
          {/* <Row>
            <Col lg={6}>
              <div className="">
                <JobVacancyList data={data} />
              </div>
            </Col>
            <Sidebar onFilter={fetchData} selectedCategory={selectedCategory} selectedCity={selectedCity} urlData={{bname:businessNameVal , city:selectedCityVal , categ:selectedCategoryVal}}/>
          </Row> */}
        </Container>
      </section>
    </React.Fragment>
  );
};

export default JobList;
