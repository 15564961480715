import React, { useState } from "react";
import { Modal, ModalBody, Input, Label, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

//Import Images
import jobImages2 from "../../../assets/images/featured-job/img-02.png";

const RightSideContent = ({data}) => {
  //Apply Now Model
  const [modal, setModal] = useState(false);
  const openModal = () => setModal(!modal);

  return (
    <React.Fragment>
      <div className="side-bar ms-lg-4">
        {/* <Card className="job-overview">
          <CardBody className="p-4">
            
            <h5 className="mb-1">{data.NAME}</h5>
            <ul className="list-inline text-muted mb-0">
              <li className="list-inline-item">{data.PROPERTY_TYPE}</li>
            
              
            </ul>
            <ul className="list-unstyled mt-4 mb-0">
              <li>
                <div className="d-flex mt-4 align-items-center">
                  <i className="uil uil-building icon  text-primary"></i>
                  <div className="d-flex gap-2">
                    <p className="text-muted mb-0">Type:</p>
                    <h6 className="fs-14 mt-1 float-right">{data.PROPERTY_TYPE}</h6>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex mt-4 align-items-center">
                  <i className="uil uil-bed icon  text-primary"></i>
                  <div className="d-flex gap-2">
                    <p className="text-muted mb-0">Bedrooms:</p>
                    <h6 className="fs-14 mt-1 float-right">{data.BEDROOMS} Bedrooms</h6>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex mt-4 align-items-center">
                  <i className="uil uil-location-point icon  text-primary"></i>
                  <div className="d-flex gap-2">
                    <p className="text-muted mb-0">Location:</p>
                    <h6 className="fs-14 mt-1 float-right">{data.ADDRESS}</h6>
                  </div>
                </div>
              </li>
              
            </ul>
           
          </CardBody>
        </Card> */}

        <Card className="company-profile mt-4">
          <CardBody className="p-4">
            {/* <div className="text-center">
             

              <div className="mt-4">
                <h6 className="fs-17 mb-1">Contact and Address</h6>
              
              </div>
            </div> */}
            <ul className="list-unstyled mt-4">
              <li>
                <div className="d-flex">
                  <i className="uil uil-user text-primary fs-4"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-1">Contractor Name</h6>
                    <p className="text-muted fs-14 mb-0">{data.AGENT_NAME}</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex mt-3">
                  <i className="uil uil-user text-primary fs-4"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-1">Years of Experience</h6>
                    <p className="text-muted fs-14 mb-0">{data.AGENT_DESCRIPTION}</p>
                  </div>
                </div>
              </li>
              {/* <li className="mt-3">
                <div className="d-flex">
                  <i className="uil uil-envelope text-primary fs-4"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Agent Number</h6>
                    <p className="text-muted fs-14 mb-0">
                      {data.PHONE}
                    </p>
                  </div>
                </div>
              </li> */}
              
            </ul>
            <a href={`https://api.whatsapp.com/send?phone=${data.AGENT_PHONE}`} target='_blank'><button type="submit" className="btn btn-success uil uil-whatsapp w-100">
                  {" "} Call us on Whatsapp
                </button></a>
            
         
          </CardBody>
        </Card>

        <div className="mt-4">
          <h6 className="fs-16 mb-3">location</h6>
          <iframe
            title="maps"
            src={`https://www.google.com/maps/search/?api=1&query=${data.LATITUDE},${data.LONGTITUDE}`}
            style={{ width: `100%`, height: `250` }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
        <div
          className="modal fade"
          id="applyNow"
          tabIndex="-1"
          aria-labelledby="applyNow"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <Modal isOpen={modal} toggle={openModal} centered>
              <ModalBody className="modal-body p-5">
                <div className="text-center mb-4">
                  <h5 className="modal-title" id="staticBackdropLabel">
                    Apply For This Job
                  </h5>
                </div>
                <div className="position-absolute end-0 top-0 p-3">
                  <button
                    type="button"
                    onClick={openModal}
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="mb-3">
                  <Label for="nameControlInput" className="form-label">
                    Name
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="nameControlInput"
                    placeholder="Enter your name"
                  />
                </div>
                <div className="mb-3">
                  <Label for="emailControlInput2" className="form-label">
                    Email Address
                  </Label>
                  <Input
                    type="email"
                    className="form-control"
                    id="emailControlInput2"
                    placeholder="Enter your email"
                  />
                </div>
                <div className="mb-3">
                  <Label for="messageControlTextarea" className="form-label">
                    Message
                  </Label>
                  <textarea
                    className="form-control"
                    id="messageControlTextarea"
                    rows="4"
                    placeholder="Enter your message"
                  ></textarea>
                </div>
                <div className="mb-4">
                  <Label className="form-label" for="inputGroupFile01">
                    Resume Upload
                  </Label>
                  <Input
                    type="file"
                    className="form-control"
                    id="inputGroupFile01"
                  />
                </div>
                <button type="submit" className="btn btn-primary w-100">
                  Send Application
                </button>
              </ModalBody>
            </Modal>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RightSideContent;
